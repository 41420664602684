.projektid-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.projektid-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.projektid-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.projektid-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.projektid-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #0000F5;
}
.projektid-hamburger:hover {
  opacity: .5;
}
.projektid-icon {
  width: 60px;
  object-fit: cover;
}
.projektid-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.projektid-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.projektid-text1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.projektid-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.projektid-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.projektid-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-navlink {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.projektid-navlink01 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.projektid-navlink02 {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.projektid-navlink03 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.projektid-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.projektid-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.projektid-icon5 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.projektid-icon7 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.projektid-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.projektid-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.projektid-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.projektid-branding {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-text2 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.projektid-text3 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.projektid-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.projektid-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-header {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.projektid-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-navlink04 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink04:hover {
  opacity: .5;
}
.projektid-navlink05 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: underline none;
}
.projektid-navlink05:hover {
  opacity: .5;
}
.projektid-navlink06 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink06:hover {
  opacity: .5;
}
.projektid-navlink07 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink07:hover {
  opacity: .5;
}
.projektid-text4:hover {
  opacity: .5;
}
.projektid-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-header1 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.projektid-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.projektid-navlink08 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink08:hover {
  opacity: .5;
}
.projektid-navlink09 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink09:hover {
  opacity: .5;
}
.projektid-navlink10 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.projektid-navlink10:hover {
  opacity: .5;
}
.projektid-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.projektid-text6 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.projektid-link {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.projektid-image {
  width: 21px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .projektid-content {
    border-top-width: 0px;
  }
  .projektid-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .projektid-branding {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .projektid-burger-menu {
    display: flex;
  }
  .projektid-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .projektid-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .projektid-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .projektid-icon {
    width: 30px;
  }
  .projektid-mobile-menu {
    padding: 16px;
  }
  .projektid-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .projektid-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .projektid-link {
    justify-content: center;
  }
}
