.kontakt-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.kontakt-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.kontakt-text {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-left: var(--dl-space-space-threeunits);
}
.kontakt-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kontakt-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #0000F5;
}
.kontakt-hamburger:hover {
  opacity: .5;
}
.kontakt-icon {
  width: 60px;
  object-fit: cover;
}
.kontakt-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.kontakt-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.kontakt-text01 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.kontakt-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kontakt-icon01 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.kontakt-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-navlink {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.kontakt-navlink01 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.kontakt-navlink02 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.kontakt-navlink03 {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration:  none;
  background-color: transparent;
}
.kontakt-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.kontakt-icon03 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.kontakt-icon05 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.kontakt-icon07 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.kontakt-steps {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.kontakt-text02 {
  color: var(--dl-color-gray-black);
  font-size: 3rem;
}
.kontakt-text03 {
  color: var(--dl-color-gray-700);
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.kontakt-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.kontakt-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.kontakt-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.kontakt-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container04 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.kontakt-icon09 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.kontakt-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container05 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.kontakt-text04 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.kontakt-text05 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.kontakt-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.kontakt-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.kontakt-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container07 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.kontakt-icon11 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.kontakt-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container08 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.kontakt-text08 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.kontakt-text09 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.kontakt-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.kontakt-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.kontakt-line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container10 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.kontakt-icon13 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.kontakt-line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container11 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.kontakt-text12 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.kontakt-text13 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.kontakt-step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.kontakt-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.kontakt-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container13 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.kontakt-icon16 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.kontakt-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.kontakt-container14 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.kontakt-text16 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.kontakt-text17 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.kontakt-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.kontakt-text20 {
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
.kontakt-text22 {
  font-weight: 600;
}
.kontakt-button {
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-halfunit);
}
.kontakt-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.kontakt-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  padding-bottom: 48px;
  justify-content: center;
}
.kontakt-link {
  display: contents;
}
.kontakt-icon18 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.kontakt-link1 {
  display: contents;
}
.kontakt-icon20 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.kontakt-link2 {
  display: contents;
}
.kontakt-icon22 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  text-decoration: none;
}
.kontakt-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.kontakt-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.kontakt-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.kontakt-branding {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-text24 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.kontakt-text25 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.kontakt-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.kontakt-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-header {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.kontakt-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-navlink04 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink04:hover {
  opacity: .5;
}
.kontakt-navlink05 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: underline none;
}
.kontakt-navlink05:hover {
  opacity: .5;
}
.kontakt-navlink06 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink06:hover {
  opacity: .5;
}
.kontakt-navlink07 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink07:hover {
  opacity: .5;
}
.kontakt-text26:hover {
  opacity: .5;
}
.kontakt-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-header1 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.kontakt-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.kontakt-navlink08 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink08:hover {
  opacity: .5;
}
.kontakt-navlink09 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink09:hover {
  opacity: .5;
}
.kontakt-navlink10 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.kontakt-navlink10:hover {
  opacity: .5;
}
.kontakt-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.kontakt-text28 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.kontakt-link3 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.kontakt-image {
  width: 21px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .kontakt-text02 {
    text-align: center;
  }
  .kontakt-text03 {
    text-align: center;
  }
  .kontakt-text20 {
    text-align: center;
  }
  .kontakt-content {
    border-top-width: 0px;
  }
  .kontakt-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .kontakt-branding {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .kontakt-burger-menu {
    display: flex;
  }
  .kontakt-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .kontakt-container02 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .kontakt-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .kontakt-container03 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .kontakt-line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-container05 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .kontakt-text04 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .kontakt-text05 {
    text-align: left;
  }
  .kontakt-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .kontakt-container06 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .kontakt-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-container08 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .kontakt-text08 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .kontakt-text09 {
    text-align: left;
  }
  .kontakt-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .kontakt-container09 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .kontakt-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-container11 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .kontakt-text12 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .kontakt-text13 {
    text-align: left;
  }
  .kontakt-step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .kontakt-container12 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .kontakt-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .kontakt-container14 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .kontakt-text16 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .kontakt-text17 {
    text-align: left;
  }
  .kontakt-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .kontakt-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .kontakt-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .kontakt-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .kontakt-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .kontakt-icon {
    width: 30px;
  }
  .kontakt-mobile-menu {
    padding: 16px;
  }
  .kontakt-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-container02 {
    padding-left: 0px;
  }
  .kontakt-container05 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-text04 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .kontakt-container08 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-text08 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .kontakt-container11 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-text12 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .kontakt-container14 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-text16 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .kontakt-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .kontakt-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .kontakt-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .kontakt-link3 {
    justify-content: center;
  }
}
