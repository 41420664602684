.saabuvad-ritused-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.saabuvad-ritused-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.saabuvad-ritused-text {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-left: var(--dl-space-space-threeunits);
}
.saabuvad-ritused-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.saabuvad-ritused-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #0000F5;
}
.saabuvad-ritused-hamburger:hover {
  opacity: .5;
}
.saabuvad-ritused-icon {
  width: 60px;
  object-fit: cover;
}
.saabuvad-ritused-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.saabuvad-ritused-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.saabuvad-ritused-text01 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.saabuvad-ritused-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.saabuvad-ritused-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.saabuvad-ritused-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-navlink {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.saabuvad-ritused-navlink01 {
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: underline none;
  background-color: transparent;
}
.saabuvad-ritused-navlink02 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.saabuvad-ritused-navlink03 {
  font-size: 20px;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: transparent;
}
.saabuvad-ritused-icon-group {
  fill: var(--dl-color-gray-white);
  display: flex;
}
.saabuvad-ritused-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.saabuvad-ritused-icon5 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.saabuvad-ritused-icon7 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.saabuvad-ritused-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.saabuvad-ritused-text02 {
  color: #000000;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.saabuvad-ritused-separator {
  width: 100px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.saabuvad-ritused-banner {
  width: 100%;
  height: 437px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  box-shadow: 10px 10px 15px 5px #d4d4d4;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/playground_assets/oras-02933-1500w.jpg");
  background-repeat: repeat;
  background-position: bottom;
}
.saabuvad-ritused-heading {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  align-self: flex-start;
  text-align: left;
}
.saabuvad-ritused-heading1 {
  color: var(--dl-color-gray-white);
  font-size: 1rem;
  align-self: flex-start;
  text-align: left;
}
.saabuvad-ritused-text06 {
  color: var(--dl-color-gray-white);
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}
.saabuvad-ritused-link {
  align-self: flex-start;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.saabuvad-ritused-link:hover {
  transform: scale(1.02);
}
.saabuvad-ritused-container2 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.saabuvad-ritused-banner1 {
  width: 100%;
  height: 437px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  box-shadow: 10px 10px 15px 5px #d4d4d4;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url("/playground_assets/pexels-patrick-case-3420517%202-1500w.jpg");
  background-repeat: repeat;
  background-position: bottom;
}
.saabuvad-ritused-heading2 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  align-self: flex-start;
  text-align: left;
}
.saabuvad-ritused-heading3 {
  color: var(--dl-color-gray-white);
  font-size: 1rem;
  align-self: flex-start;
  text-align: left;
}
.saabuvad-ritused-text07 {
  color: var(--dl-color-gray-white);
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}
.saabuvad-ritused-link1 {
  align-self: flex-start;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.saabuvad-ritused-link1:hover {
  transform: scale(1.02);
}
.saabuvad-ritused-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.saabuvad-ritused-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.saabuvad-ritused-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.saabuvad-ritused-branding {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-text08 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.saabuvad-ritused-text09 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.saabuvad-ritused-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.saabuvad-ritused-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-header {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.saabuvad-ritused-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-navlink04 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink04:hover {
  opacity: .5;
}
.saabuvad-ritused-navlink05 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  font-weight: bold;
  line-height: 32px;
  text-decoration: underline none;
}
.saabuvad-ritused-navlink05:hover {
  opacity: .5;
}
.saabuvad-ritused-navlink06 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink06:hover {
  opacity: .5;
}
.saabuvad-ritused-navlink07 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink07:hover {
  opacity: .5;
}
.saabuvad-ritused-text10:hover {
  opacity: .5;
}
.saabuvad-ritused-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-header1 {
  color: var(--dl-color-gray-black);
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.saabuvad-ritused-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.saabuvad-ritused-navlink08 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink08:hover {
  opacity: .5;
}
.saabuvad-ritused-navlink09 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink09:hover {
  opacity: .5;
}
.saabuvad-ritused-navlink10 {
  color: var(--dl-color-gray-500);
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.saabuvad-ritused-navlink10:hover {
  opacity: .5;
}
.saabuvad-ritused-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.saabuvad-ritused-text12 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.saabuvad-ritused-link2 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.saabuvad-ritused-image {
  width: 21px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .saabuvad-ritused-features {
    align-items: center;
  }
  .saabuvad-ritused-text06 {
    max-width: 100%;
  }
  .saabuvad-ritused-text07 {
    max-width: 100%;
  }
  .saabuvad-ritused-content {
    border-top-width: 0px;
  }
  .saabuvad-ritused-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .saabuvad-ritused-branding {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .saabuvad-ritused-burger-menu {
    display: flex;
  }
  .saabuvad-ritused-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-text02 {
    text-align: center;
  }
  .saabuvad-ritused-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-text06 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .saabuvad-ritused-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-text07 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .saabuvad-ritused-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .saabuvad-ritused-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .saabuvad-ritused-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .saabuvad-ritused-icon {
    width: 30px;
  }
  .saabuvad-ritused-mobile-menu {
    padding: 16px;
  }
  .saabuvad-ritused-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-banner {
    height: 513px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-text06 {
    text-align: left;
  }
  .saabuvad-ritused-link {
    width: 123px;
  }
  .saabuvad-ritused-banner1 {
    height: 513px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .saabuvad-ritused-text07 {
    text-align: left;
  }
  .saabuvad-ritused-link1 {
    width: 123px;
  }
  .saabuvad-ritused-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .saabuvad-ritused-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .saabuvad-ritused-link2 {
    justify-content: center;
  }
}
