.testimonial-card3-testimonial-card {
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.testimonial-card3-icon {
  fill: #ffffff;
  width: var(--dl-size-size-small);
  margin-bottom: var(--dl-space-space-twounits);
}
.testimonial-card3-testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimonial-card3-text {
  color: var(--dl-color-gray-900);
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.testimonial-card3-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-halfunit);
}
.testimonial-card3-text1 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 300;
}
@media(max-width: 767px) {
  .testimonial-card3-icon {
    height: var(--dl-size-size-small);
  }
}
@media(max-width: 479px) {
  .testimonial-card3-testimonial-card {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
